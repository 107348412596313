import React, { useEffect } from "react";

/* Importing the page styles */
import "../styles/onlinedonation.css";

/* Importing other components */
import {
	TextInput,
	FilledButton,
	StyledTab,
	StyledTabs,
} from "../shared/utils/components";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	InputAdornment,
	LinearProgress,
} from "@mui/material";
import { useState } from "react";
import Neon from "../shared/data/neon";
import getMailTemplate from "../shared/utils/MailTemplate";

function OnlineDonation() {
	const [don_btn, setDon_btn] = useState(false);

	const [pyt_loading, setPyt_loading] = useState(false);

	const [tab, setTab] = useState(0);

	const [validation, setValidation] = useState({
		mob_no: true,
		email: true,
		pan: true,
		amount: true,
	});

	function loadScript(src) {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

	useEffect(() => {
		loadScript("https://checkout.razorpay.com/v1/checkout.js");
	}, []);

	const [payment, setPayment] = useState({
		name: "",
		email: "",
		mob_no: "",
		flat_no: "",
		street: "",
		area: "",
		city: "",
		state: "",
		pin_code: "",
		towards: "Temple Kumbabhisekam Kaingaryam",
		amount: 0,
		remarks: "",
		final_amount: 0,
		pan: "",
		created_on: new Date(),
	});

	async function displayRazorpay() {
		setDon_btn(false);
		setPyt_loading(true);

		if (!window.Razorpay) {
			const res = await loadScript(
				"https://checkout.razorpay.com/v1/checkout.js"
			);
			if (!res) {
				alert("Unexpected error occured. Are you online?");
				return;
			}
		}

		const order = await Neon.create_order(payment);

		if (!order) {
			alert("Something went wrong !!");
			return;
		}

		const options = {
			key: process.env.REACT_APP_RZPY_KEY,
			currency: "INR",
			amount: Math.round(payment.final_amount * 100),
			order_id: order.id,
			name: "Arul Tharum Mahaperiyava Charitable Trust (ATMC Trust)",
			description: `Your donation is towards ${payment.towards}`,
			image:
				"https://arultharummahaperiyava.com/assets/images/atm_final_2.webp",
			handler: function (response) {
				setPyt_loading(true);
				Neon.verify_payment(
					response.razorpay_signature,
					order.id,
					response.razorpay_payment_id
				).then((res) => {
					if (res.verified) {
						var sendmail = false;
						if (payment.email) {
							sendmail = true;
						}

						Neon.payment_success({
							name: payment.name,
							order_id: order.id,
							email: payment.email,
							pan: payment.pan,
							mob_no: payment.mob_no,
							payment_id: response.razorpay_payment_id,
							towards: payment.towards,
							amount: payment.amount,
							created_on: payment.created_on,
							sendmail: sendmail,
							mail: {
								to: payment.email,
								cc: "arultharummahaperiyava@gmail.com",
								subject: "Thank you for your Donation",
								html: getMailTemplate(
									payment.name,
									payment.final_amount,
									payment.mob_no,
									"ATM Donation",
									response.razorpay_payment_id
								),
							},
						}).then((receipt_res) => {
							Neon.put(`/payments_received/${response.razorpay_payment_id}`, {
								...payment,
								order_id: order.id,
								payment_id: response.razorpay_payment_id,
								rzpy_signature: response.razorpay_signature,
								receipt_no: receipt_res.receipt_no,
								receipt_download_link: `https://api.prudent-solutions.co.in/neon/public/receipt/${receipt_res.receipt_no}`,
							}).then(() => {
								window.location.href = `/payments/success/${response.razorpay_payment_id}`;
							});
						});
					} else {
						window.location.href = `/payments/unverified/${response.razorpay_signature}`;
					}
				});
			},
			prefill: {
				name: payment.name,
				email: payment.email,
				contact: payment.mob_no,
			},
			notes: {
				address: payment.address,
				remarks: payment.remarks,
			},
		};

		const paymentObject = new window.Razorpay(options);
		paymentObject.on("payment.failed", function (response) {
			setPyt_loading(false);
			window.location.href = `/payments/failed/${response.error.metadata.payment_id}`;
		});

		setPyt_loading(false);

		paymentObject.open();
	}

	return (
		<div className="online-donation">
			<div className="home-banner">
				<img
					src="/assets/images/atm_banner.png"
					title="ATM Global Group"
					width="800"
					loading="eager"
					height="180"
					alt=""
				/>
			</div>

			<div className="online-donation-root">
				<div className="online-head">
					<h2>ARUL THARUM MAHAPERIYAVA CHARITABLE TRUST (ATMC TRUST)</h2>
					<p>ONLINE DONATION</p>
				</div>
				<div className="online-donation-form">
					<StyledTabs
						value={tab}
						onChange={(_, newValue) => {
							setTab(newValue);
						}}
						variant="scrollable"
						scrollButtons
						allowScrollButtonsMobile
					>
						<StyledTab label="Donation Details" />
						<StyledTab label="Personal Info" />
						<StyledTab label="Verify & Pay" />
					</StyledTabs>

					{tab === 1 ? (
						<>
							<div className="online-donation-text-input-group">
								<TextInput
									label="Name"
									style={{ width: "100%" }}
									required
									value={payment.name}
									onChange={(e) =>
										setPayment({ ...payment, name: e.target.value })
									}
								/>
								<p>
									Please enter your name as per PAN if you are providing your
									PAN number
								</p>
							</div>
							<div className="online-donation-text-input-group">
								<TextInput
									style={{ width: "100%" }}
									label="Email (Optional)"
									type="text"
									inputMode="email"
									value={payment.email}
									onChange={(e) =>
										setPayment({ ...payment, email: e.target.value })
									}
								/>
								<p>
									System generated receipt will be sent to your email after
									successful transaction
								</p>
							</div>

							<TextInput
								label="Mobile Number"
								required
								type="number"
								value={payment.mob_no}
								onChange={(e) => {
									if (e.target.value.length > 15) {
										setValidation({ ...validation, mob_no: false });
									} else {
										setValidation({ ...validation, mob_no: true });
										setPayment({ ...payment, mob_no: e.target.value });
									}
								}}
								error={!validation.mob_no}
								helperText={
									!validation.mob_no && "Mobile number cannot exceed 15 digits"
								}
							/>

							<div className="online-donation-text-input-group">
								<TextInput
									label="Flat No./Door No./Building Name"
									style={{ width: "100%" }}
									required
									value={payment.flat_no}
									onChange={(e) => {
										setPayment({ ...payment, flat_no: e.target.value });
									}}
								/>
								{/* <p>
									Please enter your correct postal address to receive Prasadam.
								</p> */}
							</div>
							<TextInput
								label="Street Name"
								required
								value={payment.street}
								onChange={(e) => {
									setPayment({ ...payment, street: e.target.value });
								}}
							/>
							<TextInput
								label="Area/District"
								required
								value={payment.area}
								onChange={(e) => {
									setPayment({ ...payment, area: e.target.value });
								}}
							/>

							<TextInput
								label="City"
								required
								value={payment.city}
								onChange={(e) => {
									setPayment({ ...payment, city: e.target.value });
								}}
							/>
							<TextInput
								label="State"
								required
								value={payment.state}
								onChange={(e) =>
									setPayment({ ...payment, state: e.target.value })
								}
							/>
							<TextInput
								label="Pincode"
								required
								value={payment.pin_code}
								onChange={(e) =>
									setPayment({ ...payment, pin_code: e.target.value })
								}
							/>

							<TextInput
								label="PAN or DL No. or Aadhar No."
								value={payment.pan}
								onChange={(e) => {
									setPayment({ ...payment, pan: e.target.value.toUpperCase() });
								}}
								required
							/>

							<div className="online-donation-btn-grp">
								<FilledButton onClick={() => setTab(tab - 1)}>
									Back
								</FilledButton>
								<FilledButton
									onClick={() => setTab(tab + 1)}
									disabled={
										!payment.name ||
										!payment.mob_no ||
										!payment.flat_no ||
										!payment.street ||
										!payment.area ||
										!payment.city ||
										!payment.state ||
										!payment.pin_code ||
										!payment.pan ||
										!validation.mob_no
									}
								>
									Next
								</FilledButton>
							</div>
						</>
					) : tab === 0 ? (
						<>
							<h3 style={{ lineHeight: 0, marginBottom: 10, padding: 0 }}>
								ATM Groups first Temple Kaingaryam
							</h3>
							<h3 style={{ lineHeight: 0, marginBottom: 10, padding: 0 }}>
								ATM’s கோவில் கும்பாபிஷேக முதல் கைங்கர்யம்
							</h3>
							<p>
								Great opportunity to participate in Kovil Kumbhabisheka
								Kaingaryam.
								<br /> <br />
								Sri Kayarohaneswarer samedha Sri Neelayadhakshi temple
								Nagaipattinam.
								<br />
								<br />
								All are requested to generously contribute.
								<br />
								<br />
								Arul Tharum Mahaperiyava Charitable Trust
								<br />
								<br />
								<br />
								கோவில் கும்பாபிஷேக கைங்கர்யத்தில் பங்கேற்க ATM Group
								பக்தர்களுக்கு சிறந்த வாய்ப்பு.
								<br />
								<br />
								ஸ்ரீ காயாரோஹண ஸ்வாமி சமேத ஸ்ரீ நீலாயதாக்ஷி அம்மன் ஆலயம்
								நாகப்பட்டினம்.
								<br />
								<br />
								இந்த கைங்கர்யத்தில் அனைவரும் தாராளமாக பங்களிக்குமாறு
								கேட்டுக்கொள்கிறோம்.
								<br />
								<br />
								அருள் தரும் மஹாபெரியவா அறக்கட்டளை
							</p>
							{/* <FormControl>
								<InputLabel id="demo-simple-select-label">
									Select Donation activity *
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									required
									variant="standard"
									style={{ marginTop: 20, marginBottom: 30 }}
									value={payment.towards}
									disabled
									onChange={(e) =>
										setPayment({ ...payment, towards: e.target.value })
									}
								>
							
									<MenuItem
										value={"ATM Samashti Bhikshavandhanam Donation Rs. 1,001"}
									>
										ATM Samashti Bhikshavandhanam Donation Rs. 1,001/-
									</MenuItem>
									<MenuItem
										value={"ATM Samashti Bhikshavandhanam Donation Rs. 1,501"}
									>
										ATM Samashti Bhikshavandhanam Donation Rs. 1,501/-
									</MenuItem>
									<MenuItem
										value={"ATM Samashti Bhikshavandhanam Donation Rs. 3,001"}
									>
										ATM Samashti Bhikshavandhanam Donation Rs. 3,001/-
									</MenuItem>
									<MenuItem
										value={"ATM Samashti Bhikshavandhanam Donation Rs. 5,001"}
									>
										ATM Samashti Bhikshavandhanam Donation Rs. 5,001/-
									</MenuItem>
									<MenuItem
										value={"ATM Samashti Bhikshavandhanam Donation Rs. 10,001"}
									>
										ATM Samashti Bhikshavandhanam Donation Rs. 10,001/-
									</MenuItem>
									<MenuItem value={"ATM Annadhanam Donation"}>
										ATM Annadhanam Donation
									</MenuItem>
									<MenuItem value={"ATM Educational Support"}>
										ATM Educational Support
									</MenuItem>
									<MenuItem value={"ATM Sath Sangam Donation"}>
										ATM Sath Sangam Donation
									</MenuItem>
									<MenuItem value={"ATM Book Printing & Gifts Donation"}>
										ATM Book Printing & Gifts Donation
									</MenuItem>
									<MenuItem value={"ATM Upanayanam Support Fund"}>
										ATM Upanayanam Support Fund
									</MenuItem>
									<MenuItem value={"ATM Marriage Support Fund"}>
										ATM Marriage Support Fund
									</MenuItem>
									<MenuItem value={"ATM Temple Donations"}>
										ATM Temple Donations{" "}
									</MenuItem>
									<MenuItem value={"ATM Gho Salai Kaingaryam"}>
										ATM Gho Salai Kaingaryam
									</MenuItem>
									<MenuItem value={"ATM Vedapatasalai Kaingaryam"}>
										ATM Vedapatasalai Kaingaryam
									</MenuItem>
									<MenuItem value={"ATM General Donation"}>
										ATM General Donation
									</MenuItem>
								</Select>
							</FormControl> */}
							<TextInput
								label="Amount (INR)"
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">₹</InputAdornment>
									),
								}}
								type="text"
								inputMode="numeric"
								value={payment.amount}
								onChange={(e) => {
									const num = Number(e.target.value);
									if (e.target.value === "") {
										setValidation({ ...validation, amount: true });
										setPayment({ ...payment, amount: 0, final_amount: 0 });
									} else {
										if (num > 1000000) {
											setValidation({ ...validation, amount: false });
										} else {
											setValidation({ ...validation, amount: true });
										}
										setPayment({
											...payment,
											amount: parseInt(e.target.value),
											final_amount: /* Math.round(
												(parseInt(e.target.value * 100) / 97.64) * 100
											) / 100 */ parseInt(e.target.value),
										});
									}
								}}
								error={!validation.amount}
								helperText={
									!validation.amount &&
									"Donation amount cannot exceed Rs. 10,00,000 per transaction"
								}
							/>

							{/* <div className="online-donation-text-input-group">
								<TextInput
									label="Remarks"
									style={{ width: "100%" }}
									multiline
									minRows={3}
									maxRows={3}
									value={payment.remarks}
									onChange={(e) =>
										setPayment({ ...payment, remarks: e.target.value })
									}
								/>
								<p>
									If you are paying Rs. 10,001 then please enter your choice of
									Prasadam gift
								</p>
							</div> */}

							<div className="online-donation-btn-grp">
								<FilledButton
									onClick={() => setTab(tab + 1)}
									disabled={
										!payment.towards || !payment.amount || !validation.amount
									}
								>
									Next
								</FilledButton>
							</div>
						</>
					) : tab === 2 ? (
						<>
							<span className="online-donation-big-p">
								<h3 style={{ fontSize: 20 }}>Please Verify your Details</h3>
								<p>
									Details submitted here cannot be modified once payment is
									made.
								</p>

								<h4>PERSONAL DETAILS</h4>
								<hr />
								<p>
									{" "}
									<strong>Name:</strong> {payment.name}
								</p>
								<p>
									{" "}
									<strong>Email: </strong>
									{payment.email || "Not Provided"}
								</p>
								<p>
									{" "}
									<strong>Mobile Number:</strong> {payment.mob_no}
								</p>
								<p>
									{" "}
									<strong>Residential Address:</strong>{" "}
									{`${payment.flat_no} ${payment.street} ${payment.area} ${payment.city} ${payment.state} - ${payment.pin_code}`}
								</p>
								<p>
									{" "}
									<strong>PAN or DL No. or Aadhar No.:</strong>{" "}
									{payment.pan || "Not Provided"}
								</p>

								<h4>DONATION DETAILS</h4>
								<hr />
								<p>
									{" "}
									<strong>Donation Amount:</strong> {payment.amount}
								</p>
								{/* <p>
									{" "}
									<strong>Total Amount to be paid*:</strong>{" "}
									{payment.final_amount}
								</p> */}
								<p>
									{" "}
									<strong>Donation Purpose:</strong> {payment.towards}
								</p>

								{/* <p>
									{" "}
									<strong>Remarks:</strong> {payment.remarks}
								</p> */}

								<br />
								{/* <p>* Includes transaction fees</p> */}
							</span>

							<div className="online-donation-btn-grp">
								<FilledButton onClick={() => setTab(tab - 1)}>
									Back
								</FilledButton>
								<FilledButton
									onClick={() => setDon_btn(true)}
									disabled={
										!payment.amount ||
										!payment.name ||
										!payment.flat_no ||
										!payment.street ||
										!payment.area ||
										!payment.city ||
										!payment.state ||
										!payment.pin_code ||
										!payment.towards ||
										!payment.mob_no ||
										!validation.pan ||
										!validation.mob_no ||
										!validation.amount
									}
								>
									PROCEED TO PAY
								</FilledButton>
							</div>
						</>
					) : (
						<p>Something went wrong...</p>
					)}
				</div>
			</div>

			<Dialog open={don_btn} onClose={() => setDon_btn(false)}>
				<DialogTitle>Online Donation</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<strong>Confirm Donation Amount</strong>
						<br />
						<br />
						Donation Amount = <strong>Rs. {payment.amount}</strong>
						<br />
						{/* 	Transaction fees = Rs. {payment.final_amount - payment.amount}{" "}
						<br />
						Total Amount to be paid = Rs. {payment.final_amount} */}
						<p>
							You will be redirected to the payment gateway for completing the
							payment.
						</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<FilledButton onClick={() => displayRazorpay()}>
						Proceed to Pay
					</FilledButton>
					<FilledButton onClick={() => setDon_btn(false)}>Cancel</FilledButton>
				</DialogActions>
			</Dialog>

			<Dialog open={pyt_loading}>
				<DialogTitle>Processing your Payment</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<h3>Please Wait</h3>
						<p>Your payment is being processed.</p>
						<p>
							<strong>Do not close or refresh this page.</strong>
						</p>
						<div
							className="pyt-progress"
							style={{
								paddingBottom: 10,
							}}
						>
							<LinearProgress variant="indeterminate" color="success" />
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default OnlineDonation;
