import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Admin from "./components/pages/Admin";
import AdminLogin from "./components/pages/AdminLogin";
import EditWeb from "./components/pages/EditWeb";
import Home from "./components/pages/Home";
// import Launch from "./components/pages/Launch";
import AboutRoute from "./components/pages/routes/AboutRoute";
import ActivitiesRoute from "./components/pages/routes/ActivitiesRoute";
import AnnouncementsRoute from "./components/pages/routes/AnnouncementsRoute";
import GalleryRoute from "./components/pages/routes/GalleryRoute";
import JoinRoute from "./components/pages/routes/JoinRoute";
import Terms from "./components/pages/Terms";
import Privacy from "./components/pages/Privacy";
import Success from "./components/pages/Success";
import Fail from "./components/pages/Fail";
import Unverified from "./components/pages/Unverified";
import Error from "./components/pages/Error";
// import DonationClosed from "./components/pages/DonationClosed";
import OnlineDonation from "./components/pages/OnlineDonation";

function App() {
	return (
		<AnimatePresence mode="wait">
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about-atm" element={<AboutRoute />} />
				<Route path="/activities" element={<ActivitiesRoute />} />
				<Route path="/announcements" element={<AnnouncementsRoute />} />
				<Route path="/gallery" element={<GalleryRoute />} />
				<Route path="/join" element={<JoinRoute />} />

				{/* <Route path="/online-donation" element={<OnlineDonation />} /> */}

				<Route path="/online-donation" element={<OnlineDonation />} />
				{/* <Route path="/online-donation-test" element={<OnlineDonation/>} /> */}

				<Route path="/terms" element={<Terms />} />
				<Route path="/privacy" element={<Privacy />} />

				<Route path="/payments/success/:id" element={<Success />} />
				<Route path="/payments/failed/:id" element={<Fail />} />
				<Route path="/payments/unverified/:id" element={<Unverified />} />

				<Route path="/admin/login" element={<AdminLogin />} />
				<Route path="/admin" element={<Admin />} />
				<Route path="/admin/website" element={<EditWeb />} />

				<Route path="/error" element={<Error />} />

				<Route path="*" element={<Home />} />
			</Routes>
		</AnimatePresence>
	);
}

export default App;
